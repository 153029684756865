.rdw-toolbar-inline {
  order: 1;
}
.rdw-toolbar-block-type {
  order: 2;
}
.rdw-toolbar-list {
  order: 3;
}
.rdw-toolbar-link {
  order: 4;
}
.rdw-toolbar-image {
  order: 5;
}
.rdw-toolbar-iframe {
  order: 6;
}
.rdw-toolbar-remove,
/* rdw issue on class applied to the wrong element */
.rdw-remove-wrapper {
  order: 7;
}
.rdw-toolbar-history {
  order: 8;
}

.rdw-link-decorator-icon {
  position: absolute;
  left: unset;
  right: -8px;
  top: -8px;
  cursor: pointer;
  background-color: white;
  opacity: 0.5;
}
.rdw-link-decorator-icon:hover {
  opacity: 1;
}
