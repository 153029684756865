.rdw-editor-main,
.DraftEditor-editorContainer,
.DraftEditor-root {
  display: flex;
  flex-flow: column nowrap;
}

.DraftEditor-editorContainer,
.DraftEditor-root,
.public-DraftEditor-content {
  flex: 1 0 auto;
}
