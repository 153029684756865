.CodeMirror,
.CodeMirror-scroll {
  height: auto;
  min-height: 150px;
  max-height: 300px;
}

.CodeMirror-scroll {
  cursor: text;
}
